"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraMode = void 0;
exports.isCameraModel = isCameraModel;
exports.isCamera = isCamera;
// Type of connection to use with a camera
var CameraMode;
(function (CameraMode) {
    // camera data is stored in the cloud
    CameraMode["Cloud"] = "Cloud";
    // when a url is used to access the camera
    // for example in the case of IOData cameras, you can install
    // a browser extension to access the camera using sockets.
    CameraMode["UrlStyle"] = "UrlStyle";
    // camera shoud be accessed locally using a sockets
    CameraMode["Local"] = "Local";
})(CameraMode || (exports.CameraMode = CameraMode = {}));
// type guards
function isCameraModel(object) {
    const _obj = object;
    if (_obj.cameraType === 'Elmo') {
        return _obj.cameraId !== undefined;
    }
    else {
        return _obj.cameraId !== undefined && _obj.currentPhotoTime >= 0;
    }
}
function isCamera(object) {
    const _obj = object;
    return _obj.data !== undefined && isCameraModel(_obj.data);
}
