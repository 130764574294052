import { action } from 'typesafe-actions';

import { LayoutActionTypes, ThemeName } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setThemeName = (theme: ThemeName) => action(LayoutActionTypes.SET_THEME_NAME, theme);
export const clearThemeName = () => action(LayoutActionTypes.CLEAR_THEME_NAME);

export const setDarkMode = (mode: string) => action(LayoutActionTypes.SET_DARK_MODE, mode);
export const clearDarkMode = () => action(LayoutActionTypes.CLEAR_DARK_MODE);

export const setFontSize = (fontSize: number) => action(LayoutActionTypes.SET_FONT_SIZE, fontSize);
export const clearFontSize = () => action(LayoutActionTypes.CLEAR_FONT_SIZE);

export const setDashboard = (dashboard: number) =>
  action(LayoutActionTypes.SET_DASHBOARD, dashboard);
export const clearDashboard = () => action(LayoutActionTypes.CLEAR_DASHBOARD);

export const setViewCollapsed = (collapsed: boolean) =>
  action(LayoutActionTypes.SET_VIEW_COLLAPSED, collapsed);

export const setViewCollapsedBy = (by: 'user' | 'width') =>
  action(LayoutActionTypes.SET_VIEW_COLLAPSED_BY, by);
