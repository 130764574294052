import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Button from '@mui/material/Button';

import Notification from '../components/Notification';
import strings from '../languages';
import * as store from '../redux';
import withRoot from '../views/withRoot';
import classNames from 'classnames';

interface PropsFromState {
  newVersionAvailable: boolean;
}

interface Props {
  className?: string;
  children?: React.ReactNode;
}

// WithStyles provides the 'classes' property that contains the classnames used by the theme...
type AllProps = Props & WrappedComponentProps & PropsFromState;

interface State {
  visible: boolean;
}

class NewVersionNotification extends React.Component<AllProps, State> {
  readonly state: State = {
    visible: false,
  };

  componentDidMount() {
    this.updateVisibility();
  }

  componentDidUpdate() {
    this.updateVisibility();
  }

  updateVisibility = () => {
    const { newVersionAvailable } = this.props;
    const { visible } = this.state;
    if (visible !== newVersionAvailable) {
      this.setState({ visible: newVersionAvailable });
    }
  };

  // user must refresh...
  closeVersionNotification = (event: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      // user must explicitly close the notification
      return;
    }
  };

  onRefresh = () => {
    // console.log("reloading application");
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister().then(() => {
        window.location.replace(process.env.PUBLIC_URL);
      });
    });
    // window.location.reload();
  };

  onClickAway = () => {
    // empty right now...
  };

  render = () => {
    const { className, intl, newVersionAvailable } = this.props;
    const { visible } = this.state;
    const localized = strings(intl);
    const action = [
      <Button
        key="refresh"
        size="small"
        color="secondary"
        variant="contained"
        autoCapitalize="false"
        onClick={this.onRefresh}
      >
        {localized.action.refresh()}
      </Button>,
    ];

    if (visible) {
      // const listenerProps: ClickAwayListenerProps = {
      //   children: ,
      //   onClickAway: this.onClickAway,
      // };

      return (
        <Notification
          variant="info"
          className={classNames(className, 'AC-newVersionNotification')}
          // duration={undefined}
          onClose={this.closeVersionNotification}
          message={localized.serviceWorker.newVersion()}
          visible={newVersionAvailable}
          action={action}
          closeButton={false}
          // ClickAwayListenerProps={listenerProps}
        />
      );
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state: store.ApplicationState) => ({
  newVersionAvailable: state.serviceWorker.newVersionAvailable,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRoot(NewVersionNotification)));
