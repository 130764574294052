"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SleepReportService = void 0;
const logger_1 = require("../logger");
const logger = (0, logger_1.getLogger)('/services/SleepReportService');
/**
 * Service for retrieving sleep reports. It uses the AC API to retrieve sleep reports.
 * Can be used by the UI.
 *
 * N.B. It should not be used by the API.
 */
class SleepReportService {
    constructor(db) {
        /**
         * Gets any sleeps reports the are available on the specified time period.
         * A date is passed in. The sleep session for that and the previous number of
         * days is retrieved.
         *
         * @param token API JWT token to use in request
         * @param resident The resident whose sleep report is being looked up
         * @param date The date of the sleep report.
         * @param days The previous number of days of sleep reports to include in the results.
         *
         * @return The sleep reports.
         */
        this.getSleepReports = (resident_1, date_1, ...args_1) => __awaiter(this, [resident_1, date_1, ...args_1], void 0, function* (resident, date, days = 1) {
            const all = [];
            const dates = [];
            for (let i = 0; i <= days; i++) {
                dates.push(new Date(date.getTime() - i * 24 * 60 * 60 * 1000));
            }
            const reports = yield Promise.all(dates.map((d) => this.getSleepReport(resident, d)));
            reports.forEach((s) => all.push(...s));
            return all.sort((a, b) => a.startTime.localeCompare(b.startTime));
        });
        // getSleepReports = async (
        //   resident: Resident,
        //   date: Date,
        //   reportDates?: Date[],
        //   days = 1
        // ): Promise<SleepReport[]> => {
        //   const dates: Date[] = [];
        //   if (days === 1 || reportDates == undefined) {
        //     logger.debug('routeA');
        //     dates.push(date);
        //   } else if (reportDates.length >= days) {
        //     logger.debug('routeB');
        //     const minDate = date.getTime() - days * 24 * 60 * 60 * 1000;
        //     for (let i = 0; i < reportDates.length; i++) {
        //       if (minDate < reportDates[i].getTime() && reportDates[i].getTime() <= date.getTime()) {
        //         dates.push(reportDates[i]);
        //       }
        //     }
        //   } else {
        //     logger.debug('routeC');
        //     for (let d = reportDates, i = 0; i < reportDates.length; i++) {
        //       dates.push(d[i]);
        //     }
        //   }
        //   logger.debug('getSleepReports:', { dates });
        //   const reports = await Promise.all(dates.map((d) => this.getSleepReport(resident, d)));
        //   const all: SleepReport[] = [];
        //   reports.forEach((s) => all.push(...s));
        //   return all.sort((a, b) => a.startTime.value.localeCompare(b.startTime.value));
        // };
        /**
         * Retrieve a sleep session for a single date.
         *
         * @param resident The resident whose sleep session is beingn looked up
         * @param date The date of the sleep session.
         * @returns The sleep sessions on that date.
         */
        this.getSleepReport = (resident, date) => __awaiter(this, void 0, void 0, function* () {
            // use the API
            const api = yield this.db.api();
            const apiReports = yield api.sleep.sleepReportGet({
                residentId: resident.snapshot.id,
                t: date.toISOString(),
            });
            const sleepReports = yield Promise.all(apiReports.map((r) => __awaiter(this, void 0, void 0, function* () {
                const sensor = yield this.db.sensors.findById(r.sensorId);
                if (sensor) {
                    // return sleepReportMapper.apiSleepReportToBQSleepReport(sensor, r);
                    return r;
                }
                else {
                    return undefined;
                }
            })));
            return sleepReports.filter((r) => r !== undefined);
        });
        /**
         * Gets most recent sleep report start times.
         *
         * @param resident The resident whose sleep session is being looked up
         * @param limit Limit the number of responses.
         *
         * @return The sleep report start times.
         */
        this.getSleepReportStartTimes = (resident, startDate, endDate, limit) => __awaiter(this, void 0, void 0, function* () {
            logger.debug('getSleepReportStartTimes:', { startDate, endDate });
            const api = yield this.db.api();
            const times = yield api.sleep.sleepReportStartTimesGet({
                residentId: resident.snapshot.id,
                startDate: startDate,
                endDate: endDate,
                limit: limit,
            });
            logger.debug('getSleepReportStartTimes:', { times });
            return times.map((t) => new Date(t));
        });
        this.db = db;
    }
}
exports.SleepReportService = SleepReportService;
