"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const firestore_1 = require("firebase/firestore");
const logger_1 = require("../logger");
const models_1 = require("../models");
const logger = (0, logger_1.getLogger)('/services/FeedbackService');
logger.debug('debug is enabled');
/**
 * Feedback is built on the alert framework. This service provides
 * the services to manage feeback notifications.
 */
class FeedbackService {
    constructor(db) {
        this.db = db;
    }
    get alerts() {
        return this.db.alerts;
    }
    /**
     * Gets all feedback for a resident.
     * @param resident the resident to get feedback for
     */
    getFeedback(resident, key) {
        return __awaiter(this, void 0, void 0, function* () {
            const alerts = yield this.alerts.getResidentAlerts(resident);
            const feedbackAlerts = alerts.filter((alert) => {
                if (this.alerts.isFeedbackAlert(alert)) {
                    if (key) {
                        return alert.data.feedback && alert.data.feedback.key === key;
                    }
                    return true;
                }
                return false;
            });
            return feedbackAlerts.filter((a) => !this.alerts.isFeedbackExpired(a));
        });
    }
    /**
     * Add feedback for a resident.
     * Note, if feedback with the same key already exists, it will be removed.
     *
     * @param resident the resident to add feedback for
     * @param feedback the feedback to add
     * @param device the device or sensor that is related to the feedback (optional)
     */
    addFeedback(resident, feedback, device) {
        return __awaiter(this, void 0, void 0, function* () {
            const sensorType = device
                ? (0, models_1.isNurseCall)(device)
                    ? models_1.SensorType.NURSE_CALL
                    : device.data.sensorType
                : models_1.SensorType.FEEDBACK;
            const alert = {
                alertType: models_1.AlertType.FEEDBACK,
                alertStatusType: models_1.AlertStatusType.OPEN,
                careCenterRef: resident.data.careCenterRef,
                residentRef: resident.snapshot.ref.path,
                feedback: feedback,
                sensorId: device ? device.id : null,
                sensorType: sensorType,
                riskLevel: models_1.RiskLevelType.NONE,
                alertTime: firestore_1.Timestamp.now(),
                // following are not used by feedback alerts
                snoozedTime: null,
                snoozedDuration: 0,
                sensorAlertRef: null,
                vitalsStartTime: null,
                vitalsEndTime: null,
                vitalsTriggeringValue: null,
                vitalsTriggeringAlertSettings: null,
                resolvedDate: null,
                resolvedUserUid: null,
                resolvedUserPersonRef: null,
                resolvedActionTypes: [],
                resolvedNote: null,
            };
            yield this.removeResidentFeedback(resident, feedback.key);
            return this.alerts.addAlert(alert);
        });
    }
    /**
     * Remove feedback alerts for a resident. If feedbackKey is provided, only
     * remove the feedback with that key.
     * @param resident the resident to remove feedback for
     * @param feedbackKey the key of the feedback to remove (optional)
     */
    removeResidentFeedback(resident, feedbackKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const feedback = yield this.getFeedback(resident, feedbackKey);
            yield Promise.all(feedback.map((alert) => this.removeFeedback(alert)));
            return feedback.length > 0;
        });
    }
    /**
     * Remove all feedback alerts for a device.
     * @param device the device to remove feedback for
     */
    removeDeviceFeedback(device) {
        return __awaiter(this, void 0, void 0, function* () {
            const sensorId = device.snapshot.id;
            const alerts = yield this.alerts.getOpenAlerts(device.data.careCenterRef);
            const feedbackAlerts = alerts.filter((a) => a.data.sensorId === sensorId && a.data.alertType === models_1.AlertType.FEEDBACK);
            yield Promise.all(feedbackAlerts.map((a) => this.removeFeedback(a)));
        });
    }
    /**
     * Transfer feedback from one resident to another.
     * @param feedbackKey the feedback key to transfer
     * @param from the resident to transfer feedback from
     * @param to the resident to transfer feedback to. Null it it should be deleted.
     */
    transferFeedback(feedbackKey, from, to) {
        return __awaiter(this, void 0, void 0, function* () {
            if (feedbackKey === models_1.NurseCallFeedbackKeys.BestReha) {
                const feedback = yield this.getFeedback(from, feedbackKey);
                const toResidentRef = to ? this.db.getRefPath(models_1.Collection.resident, to) : null;
                yield Promise.all(feedback.map((f) => __awaiter(this, void 0, void 0, function* () {
                    if (!toResidentRef) {
                        yield this.removeFeedback(f);
                    }
                    else {
                        f.data.residentRef = toResidentRef;
                        this.db.alerts.setAlert(f.id, f.data, 0);
                    }
                })));
            }
        });
    }
    removeFeedback(feedback) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.alerts.hardDeleteAlert(feedback);
        });
    }
    syncFeedback(center) {
        return __awaiter(this, void 0, void 0, function* () {
            const alerts = yield this.alerts.getOpenAlerts(center.snapshot.ref.path);
            return true;
        });
    }
}
exports.default = FeedbackService;
