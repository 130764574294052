import * as Icons from '../../icons';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { CancelButton } from '../buttons';
import Dialog from './Dialog';
import { Stack } from '@mui/system';
// import UserLabel from '../UserLabel';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

type Props = ComponentProps & WrappedComponentProps;

export const SupportingDialog = injectIntl((props: Props) => {
  const { onClose, open, title, message } = props;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="add-device-dialog-title"
      open={open}
      // fullScreen={device.isExtraSmall(width)}
      maxWidth="sm"
    >
      <DialogTitle id="add-device-dialog-title">
        <Icons.NurseCall fontSize="large" />
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <Stack className="AC-infoSpacing">
          <Typography>{message}</Typography>
          {/* <UserLabel user="ToDo Yoshida" /> */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
});
