import * as React from 'react';

import 'typeface-roboto';
// import './App.css';

import { UserPersonTheme } from 'attentive-connect-store/dist/models/UserPerson';
import { FirebaseRuntime } from 'attentive-connect-store/dist/services/Firebase';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AppAuth from './AppAuth';
import deployment from './Deployment';
import { getLogger } from './logger';
import * as store from './redux';
import PollingService from './services/PollingService';
import AudioNotificationService from './services/AudioNotificationService';
import env from 'attentive-connect-store/dist/config/Environment';
import AlertNotificationService from './services/AlertNotificationService';
import NurseCallHeartBeatService from './services/NurseCallHeartBeatService';

const logger = getLogger('App');

interface PropsFromRedux {
  // locale: localeStore.LocaleState,
  auth: store.auth.AuthState;
  themeFontSize?: number;
  themeDarkMode?: string;
  themeName?: UserPersonTheme;
}

interface AppState {
  offline: boolean;
  offlineAt: Date | null;
}

type AppProps = PropsFromRedux & WrappedComponentProps;

class App extends React.Component<AppProps, AppState> {
  readonly state: AppState = {
    offline: false,
    offlineAt: null,
  };
  private offlineIntervald?: NodeJS.Timeout;

  async componentDidMount() {
    // push notifications - moved to AppAuth
    // await push.setAppId(pushAppId());
    if (this.offlineIntervald) {
      clearInterval(this.offlineIntervald);
    }
    this.offlineIntervald = setInterval(this.checkConnection, 10000);
  }

  componentDidUpdate() {
    // place holder...
  }

  checkConnection = () => {
    logger.debug('checkConnection');
    FirebaseRuntime.instance
      .checkFirestoreConnection()
      .then(() => {
        logger.debug('offline: false');
        if (this.state.offline) {
          this.setState({ offline: false });
        }
      })
      .catch(() => {
        logger.debug('checkConnection failed');
        if (!this.state.offline) {
          this.setState({ offline: true, offlineAt: new Date() });
          logger.debug('offline: true');
        }
        this.forceUpdate();
      });
  };

  // need this in order to support deployment in a sub-directory
  // @see Router.basename
  location = () => deployment.publicUrl.pathname;

  offlineDuration = (): number => {
    const now = new Date();
    if (this.state.offline && this.state.offlineAt) {
      const diff = now.getTime() - this.state.offlineAt.getTime();
      return diff;
    }
    logger.debug('isOffline', false);
    return 0;
  };

  render() {
    logger.debug('render');
    // const views = <RouterProvider router={env.isHybrid() ? hashRouter : browserRouter} />;

    return (
      <React.Fragment>
        <AppAuth offline={this.offlineDuration() > 10000} />
        <AlertNotificationService />
        <PollingService pollInterval={25000} polling="biobeat-vitals" />
        <PollingService pollInterval={1001} polling="aisleep-vitals" />
        <PollingService pollInterval={5001} polling="sensingWave-vitals" />
        {env().isEmulator() ? null : (
          <PollingService pollInterval={61000} polling="bed-occupancy" />
        )}
        <PollingService pollInterval={7000} polling="current-version" />
        <AudioNotificationService />
        <NurseCallHeartBeatService />
      </React.Fragment>
    );
  }
}

// map redux ApplicationState to component properties
const mapStateToProps = ({ auth, layout }: store.ApplicationState) => ({
  auth,
  themeFontSize: layout.fontSize,
  themeDarkMode: layout.darkMode,
  themeName: layout.themeName,
});

// map redux dispatch actions to component properties
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguageFromBrowser: () => dispatch(store.locale.setLanguageFromBrowser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
