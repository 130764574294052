"use strict";
/**
 * Generic error that should be able to support api call errors.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = void 0;
exports.isErrorMessage = isErrorMessage;
/**
 * Error that contains a code and detail.
 */
class ErrorMessage extends Error {
    /**
     * Generic error message.
     *
     * @param status status code, for example an HTTP status code.
     * @param code the error code, should be a key that can be looked up in a translation file
     * @param message the message that can be logged
     * @param messageParams paramaters to apply to the code when translated.
     * @param cause details that should be logged and useful for debugging
     */
    constructor(status, code, message, messageParams, cause) {
        super(message + ' (' + code + ')');
        this.status = status;
        this.code = code;
        this.message = message;
        this.messageParams = messageParams;
        this.cause = cause;
    }
}
exports.ErrorMessage = ErrorMessage;
// type guard
function isErrorMessage(error) {
    const errorMessage = (error || {});
    return errorMessage.status !== undefined && errorMessage.code !== undefined;
}
exports.default = ErrorMessage;
