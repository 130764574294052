import * as React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { loadTheme } from '../themes';

interface Props {
  darkMode?: string | undefined;
  fontSize?: number | undefined;
  children?: React.ReactNode;
}

const SystemThemeProvider = (props: Props): React.JSX.Element => {
  const { children, darkMode, fontSize } = props;
  let useMode = 'system';
  if (darkMode === 'dark' || darkMode === 'light') {
    useMode = darkMode;
  }
  if (useMode === 'system') {
    const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    useMode = systemDarkMode ? 'dark' : 'light';
  }
  // console.log('darkMode = ' + darkMode);
  // console.log('fontSize = ' + fontSize);
  // console.log('useMode = ' + useMode);
  const currentTheme = () => loadTheme(useMode, fontSize);

  return <ThemeProvider theme={currentTheme()}>{children}</ThemeProvider>;
};

export default SystemThemeProvider;
