import { UserPersonTheme } from 'attentive-connect-store/dist/models/UserPerson';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum LayoutActionTypes {
  SET_THEME_NAME = '@@layout/SET_THEME_NAME',
  CLEAR_THEME_NAME = '@@context/CLEAR_THEME_NAME',

  SET_DARK_MODE = '@@layout/SET_DARK_MODE',
  CLEAR_DARK_MODE = '@@layout/CLEAR_DARK_MODE',

  SET_FONT_SIZE = '@@layout/SET_FONT_SIZE',
  CLEAR_FONT_SIZE = '@@layout/CLEAR_FONT_SIZE',

  SET_DASHBOARD = '@@layout/SET_DASHBOARD',
  CLEAR_DASHBOARD = '@@layout/CLEAR_DASHBOARD',

  SET_VIEW_COLLAPSED = '@@layout/SET_VIEW_COLLAPSED',
  SET_VIEW_COLLAPSED_BY = '@@layout/SET_VIEW_COLLAPSED_BY',

  SET_WIDTH = '@@layout/SET_WIDTH',
  CLEAR_WIDTH = '@@layout/CLEAR_WIDTH',

  SET_RESIZING = '@@layout/SET_RESIZING',
  CLEAR_RESIZING = '@@layout/CLEAR_RESIZING',
}

export type ThemeName = UserPersonTheme;

export interface LayoutState {
  readonly themeName?: ThemeName;
  readonly darkMode?: string;
  readonly fontSize?: number;
  readonly dashboard?: number;
  readonly viewCollapsed?: boolean;
  readonly viewCollapsedBy?: 'user' | 'width';
}
