import * as React from 'react';

import { Store as Database } from 'attentive-connect-store/dist';
import CareCenter from 'attentive-connect-store/dist/models/CareCenter';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getLogger } from '../logger';
import * as redux from '../redux';
import { Collection, NurseCall } from 'attentive-connect-store/dist/models';

const logger = getLogger('services/NurseCallHeartBeatService', 'info');
logger.debug();

const NAMESPACE = 'ui/services/NurseCallHeartBeatService';

interface StateProps {
  // props from state (redux)
  db?: Database;
  center?: CareCenter | null;
}

const mapState = (application: redux.ApplicationState) => {
  const stateProps: StateProps = {
    db: application.auth.database,
    center: application.context.center,
  };
  return stateProps;
};

const mapDispatch = (_: Dispatch) => {
  const dispatchProps = {};
  return dispatchProps;
};

const NurseCallHeartBeatService: React.FC<StateProps> = ({ db, center }) => {
  const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const [stats, setStats] = React.useState(-1);
  const [devices, setDevices] = React.useState<NurseCall[]>([]);
  const [feedbackUpdated, setFeedbackUpdated] = React.useState(Date.now());

  logger.debug({ center: center?.data, stats, devices });

  const loadDevices = async () => {
    if (db && center) {
      const devices = await db.nurseCall.getNurseCalls(center);
      setDevices([...devices]);
    } else {
      setDevices([]);
    }
  };

  // Load devices when center changes or if nurse call stats change
  React.useEffect(() => {
    loadDevices();
  }, [center?.id, stats]);

  // Setup listener for nurse call device stats
  React.useEffect(() => {
    if (db && center) {
      db.stats.listen(NAMESPACE, Collection.nurse_calls, (stats) => {
        logger.debug('NurseCallHeartBeatService stats listener', stats);
        setStats(stats.count);
      });
    }

    return () => {
      db?.listeners.stopAllInNamespace(NAMESPACE);
    };
  }, [center?.id]);

  // Set timeout to check for heartbeats
  React.useEffect(() => {
    async function updateFeedback(updateState = true) {
      if (db && center) {
        await Promise.all(
          devices.map((device) =>
            db.nurseCall
              .refresh(device)
              .then((device) => device && db.nurseCall.updateFeedback(device))
          )
        );
        if (updateState) {
          setFeedbackUpdated(Date.now());
        }
      }
    }
    async function configureTimeout() {
      if (db && center) {
        const expectedHeartBeat = await Promise.all(
          devices.map((device) => db.nurseCall.expectedHeartBeatTime(device))
        );
        const time = Math.min(...expectedHeartBeat);
        if (time !== Infinity) {
          const delta = time - Date.now();
          const timeout = delta > 0 ? delta : 60 * 1000;
          timeoutRef.current = setTimeout(updateFeedback, timeout);
          logger.debug('NurseCallHeartBeatService timeout', { time, timeout });
          if (delta <= 0) {
            updateFeedback(false);
          }
        } else {
          logger.debug('NurseCallHeartBeatService no timeout');
        }
      }
    }
    configureTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [devices, feedbackUpdated]);

  // This component does not render anything
  return <></>;
};

export default connect(mapState, mapDispatch)(NurseCallHeartBeatService);
