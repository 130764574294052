import { SensorStatus } from 'attentive-connect-store/dist/models';
import { Stack, Tooltip } from '@mui/material';
import * as Icons from '../icons';
import { getLogger } from '../logger';
import { Box_StatusLED } from '../themes/Styled';
import classNames from 'classnames';

const logger = getLogger('ui/components/SensorLEDs');

type LedVariant = 'small' | 'medium' | 'large' | 'inherit';

interface LedPanelProps {
  className?: string;
  status: SensorStatus[];
  info?: string[];
  variant?: LedVariant;
  icon?: boolean;
}

interface LedStatus {
  index: number;
  sensorStatus: SensorStatus;
  info?: string;
}

export const LedPanel = (props: LedPanelProps) => {
  const { status, info, variant, icon } = props;
  logger.debug('LedPanel', { status, info, variant, icon });
  if (status.length > 0) {
    const ledStatus = status
      .map((sensorStatus, index) => {
        const l = info && index < info.length ? info[index] : undefined;
        const s: LedStatus = { index, sensorStatus, info: l };
        return s;
      })
      .sort((a, b) => {
        if (a.info === b.info) {
          return 0;
        } else if (a.info === undefined) {
          return -1;
        } else if (b.info === undefined) {
          return 1;
        } else {
          return a.info.localeCompare(b.info);
        }
      });

    logger.debug('leds', { ledStatus });
    return (
      <Stack direction="row" sx={{ px: 2 }}>
        {ledStatus.map((s) => (
          <LED status={s.sensorStatus} info={s.info} variant={variant} icon={icon} key={s.index} />
        ))}
      </Stack>
    );
  } else {
    return null;
  }
};

interface LedProps {
  className?: string;
  status: SensorStatus;
  info?: string;
  variant?: LedVariant;
  icon?: boolean;
}

export const LED = (props: LedProps) => {
  const { status, info, variant, icon } = props;
  let led = <Icons.StatusNull />;
  const size = variant ? variant : 'large';

  logger.debug('LED', { info, variant, icon, size });

  switch (status) {
    case 'ok':
      led = <Icons.StatusSuccess fontSize={size} color="success" className="AC-led" />;
      break;
    case 'nok':
      led = <Icons.StatusError fontSize={size} color="error" className="AC-led" />;
      break;
    case 'warn':
      led = <Icons.StatusWarning fontSize={size} color="warning" className="AC-led" />;
      break;
    case 'limited-data':
      led = (
        <Icons.StatusConnecting
          fontSize={size}
          color="warning"
          className={classNames('AC-led', 'AC-ledSpin')}
        />
      );
      break;
    default:
      led = <Icons.StatusNull fontSize={size} color="disabled" className="AC-led" />;
  }

  if (info && info.length > 0) {
    return (
      <Box_StatusLED className="AC-statusLED">
        <Tooltip title={info} placement="top" arrow>
          {led}
        </Tooltip>
      </Box_StatusLED>
    );
  } else {
    return <Box_StatusLED className="AC-statusLED">{led}</Box_StatusLED>;
  }
};
