"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACError = void 0;
exports.isACError = isACError;
const ACErrorCodes_1 = require("./ACErrorCodes");
/**
 * Error that contains a code and detail. Can be used to provide tranlated error messages
 * in the UI.
 */
class ACError extends Error {
    /**
     * AC error that can be used to localize messages.
     *
     * @param logMessage the message that can be logged
     * @param code the error code, should be a key that can be looked up in a translation file
     * @param translationParams paramaters to apply to the code when translated.
     * @param httpStatus the HTTP status code to return
     * @param cause the cause of the error
     */
    constructor(logMessage, code = ACErrorCodes_1.ACErrorCodes.ErrorOther, translationParams, httpStatus, cause) {
        super(logMessage ? logMessage + ' (' + code + ')' : code);
        this.logMessage = logMessage;
        this.code = code;
        this.translationParams = translationParams;
        this.httpStatus = httpStatus;
        this.cause = cause;
        this.isACError = true;
    }
}
exports.ACError = ACError;
// type guard
function isACError(error) {
    const _error = error;
    return _error.isACError;
}
// export const isAttentiveError = (e: AttentiveError | Error | unknown): boolean => {
//   const err = e as AttentiveError;
//   return err.isAttentiveError ? true : false;
// };
exports.default = ACError;
