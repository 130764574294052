import { Theme } from '@mui/material';
import { Store as Database } from 'attentive-connect-store/dist';
import * as models from 'attentive-connect-store/dist/models';
import * as types from 'attentive-connect-store/dist/types';
import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import * as Aisleep from './Aisleep';
import * as Alerts from './Alerts';
import * as Residents from './Residents';
import * as SensingWave from './SensingWave';
import {
  InBedStatusType,
  SittingStatusType,
  SleepStatusType,
  aisleepInBedStatus,
  aisleepSleepStatus,
  getAisleepVitals,
  getBioBeatVitals,
  getBleVitals,
  getSensingWaveVitals,
  sensingWaveInBedStatus,
  sensingWaveSleepStatus,
} from './Sensors';
import * as Vitals from './Vitals';
import deepEqual from 'deep-equal';
import { AlertType } from 'attentive-connect-store/dist/models';

const logger = getLogger('data/Dashboard', 'info');

export interface TileData {
  db: Database;
  center: models.CareCenter;
  resident: models.Resident;
  bioBeatVitals?: Vitals.BioBeatVitals;
  aisleepVitals?: Vitals.AisleepVitals;
  sensingWaveVitals?: Vitals.SensingWaveVitals;
  alerts: Alerts.AlertViewData[];
  feedbackAlerts: Alerts.AlertViewData[];
  sensors: models.Sensor[];
  sensorsStatus: models.SensorStatus[];
  sensorsInfo: string[];
  cameras: models.Camera[];
  // camerasVisibility: boolean[];
  roomTemp: number | undefined;
  bleVitals?: types.acApi.Vitals[];
  contextMenuIsOpen: boolean;
  bedOccupancy: models.BedOccupancy[];
}

export const getTileData = (
  theme: Theme,
  db: Database,
  center: models.CareCenter | undefined,
  residents: models.Resident[],
  sensors: models.Sensor[],
  cameras: models.Camera[],
  bioBeatVitals: Vitals.BioBeatVitals[],
  aisleepVitals: Vitals.AisleepVitals[],
  sensingWaveVitals: Vitals.SensingWaveVitals[],
  bleVitals: Record<string, types.acApi.Vitals[]> | undefined,
  alerts: models.AlertWithSensorDetail[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl,
  nurseCalls: models.NurseCall[],
  persons: models.UserPerson[],
  contextMenuIsOpen = false
): TileData[] => {
  const tiles: TileData[] = [];
  if (center) {
    residents.forEach((r) => {
      const residentSensors = filterResidentSensors(r, sensors);
      const residentCameras = cameras.filter((c) => c.data.residentRef === r.snapshot.ref.path);
      // if (bleVitals) {
      //   console.log(getBleVitals(r, bleVitals, localized));
      // }
      if (residentSensors.length || residentCameras.length) {
        const _alerts = sortAlerts(
          getAlerts(
            r,
            Alerts.toAlertViewDataListWithSensorDetail(
              theme,
              alerts,
              sensors,
              residents,
              [],
              persons,
              nurseCalls
            )
          ).filter((a) => !a.isOneClickHidden()),
          localized
        );
        const _feedbackAlerts = sortAlerts(
          getFeedbackAlerts(
            r,
            Alerts.toAlertViewDataListWithSensorDetail(
              theme,
              alerts,
              sensors,
              residents,
              [],
              persons,
              nurseCalls
            )
          ).filter((a) => !a.isOneClickHidden()),
          localized
        );

        const newTileData: TileData = {
          db,
          center,
          resident: r,
          // bioBeat vitals to be displayed in dashboard.
          bioBeatVitals: getBioBeatVitals(r, sensors, bioBeatVitals, localized),
          aisleepVitals: getAisleepVitals(r, sensors, aisleepVitals, localized),
          sensingWaveVitals: getSensingWaveVitals(r, sensors, sensingWaveVitals, localized),
          bleVitals: bleVitals ? getBleVitals(r, bleVitals, localized) : undefined,
          // N.B. we aren't passing users in because only dealing with open alerts
          // users is only used for resolved alerts
          alerts: _alerts,
          feedbackAlerts: _feedbackAlerts,
          sensors: residentSensors.sort((a, b) =>
            a.snapshot.ref.path.localeCompare(b.snapshot.ref.path)
          ),
          cameras: residentCameras,
          // camerasVisibility: residentCameras.map(() => showCamera),
          roomTemp: undefined,
          sensorsStatus: [],
          sensorsInfo: [],
          contextMenuIsOpen,
          bedOccupancy,
        };
        if (logger.isDebugEnabled()) {
          logger.debug('getTileData', {
            center: newTileData.center.id,
            resident: newTileData.resident.id,
            alerts: newTileData.alerts.length,
            feedbackAlerts: newTileData.feedbackAlerts.length,
            cameras: newTileData.cameras.length,
            sensors: newTileData.sensors.length,
          });
        }
        tiles.push(newTileData);
      }
      tiles.forEach((t) => {
        t.sensorsStatus = sensorStatus(db, t, bioBeatVitals, aisleepVitals, sensingWaveVitals);
        t.sensorsInfo = sensorInfo(t, localized);
      });
    });
  }
  return sortTilesByPriority(tiles, bedOccupancy, localized);
};

const filterResidentSensors = (resident: models.Resident, sensors: models.Sensor[]) =>
  sensors.filter((s) => s.data.residentRef === resident.snapshot.ref.path);

const sensorStatus = (
  db: Database,
  tile: TileData,
  bioBeatVitals: Vitals.BioBeatVitals[],
  aisleepVitals: Vitals.AisleepVitals[],
  sensingWaveVitals: Vitals.SensingWaveVitals[]
): models.SensorStatus[] => {
  return tile.sensors.map((s) => {
    if (db.sensors.isBioBeatSensor(s.data)) {
      logger.debug('BIOBEAT');
      const vitals: types.biobeat.Vitals[] = [];
      const bbVitals = bioBeatVitals.find((v) => {
        if (s.data.biobeat) {
          return v.sensorId === s.data.biobeat.Patch_ID;
        }
        return false;
      });
      bioBeatVitals.forEach((v) => v.vitals && vitals.push(v.vitals));

      const status = db.sensors.getBioBeatVitalsStatus(bbVitals ? bbVitals.vitals : undefined);

      // logger.debug("BIOBEAT status", { status, sensor: s.data, vitals });

      // RETURN
      return status;
    } else if (db.sensors.isAisleepSensor(s.data)) {
      const x = aisleepVitals.find((y) => s.snapshot.id === y.sensor.snapshot.id);
      const status = db.sensors.getTimedAisleepVitalsStatus(
        tile.center,
        x ? x.sensor.data.aisleep : null,
        x ? x.vitals.data.aisleep : null
      );

      // RETURN
      return status;
    } else if (db.sensors.isSensingWaveSensor(s.data)) {
      const x = sensingWaveVitals.find((y) => s.snapshot.id === y.sensor.snapshot.id);
      const status = db.sensors.getTimedSensingWaveVitalsStatus(
        tile.center,
        x ? x.sensor.data.sensingWave : null,
        x ? x.vitals.data.sensingWave : null
      );

      // RETURN
      return status;
    } else {
      logger.notice('Sensor is not recognized.', { sensor: s.data });
      // this should not happen
      return 'other';
    }
  });
};

const sensorInfo = (tile: TileData, localized: StringsIntl): string[] => {
  return tile.sensors.map((s) => {
    let info = '';
    switch (s.data.sensorType) {
      case models.SensorType.BIOBEAT:
        info = localized.biobeat.biobeat();
        if (s.data.biobeat) {
          info += ' (' + s.data.biobeat.Patch_ID + ')';
        }
        break;
      case models.SensorType.AISLEEP:
        info = localized.aisleep.aisleep();
        if (s.data.aisleep) {
          info += ' (' + s.data.aisleep.serialNumber + ')';
        }
        break;
      case models.SensorType.SENSING_WAVE:
        info = localized.sensingWave.sensingWave();
        if (s.data.sensingWave) {
          info += ' (' + s.data.sensingWave.serialNumber + ')';
        }
        break;
    }
    return info;
  });
};

export const sortTilesByPriority = (
  tiles: TileData[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  return tiles.sort((a, b) => {
    if (a.alerts.length > b.alerts.length) {
      return -1;
    }
    if (a.alerts.length < b.alerts.length) {
      return 1;
    }
    if (a.alerts.length > 0) {
      const c = Alerts.alertCompare(a.alerts[0], b.alerts[0], localized);
      if (c !== 0) {
        return c;
      }
    }
    return inBedStatusCompare(a, b, bedOccupancy, localized);
    // return Residents.roomCompare(a.resident, b.resident, localized);
  });
};

export const sortTilesByRoom = (tiles: TileData[], localized: StringsIntl) => {
  return tiles.sort((a, b) => {
    let compare = Residents.highPriorityDispCompare(a.resident, b.resident);

    if (compare === 0) {
      compare = Residents.roomCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.residentCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.bedCompare(a.resident, b.resident, localized);
    }
    if (compare === 0) {
      compare = Residents.floorCompare(a.resident, b.resident, localized);
    }

    return compare;
  });
};

export const sortTilesByInBedStatus = (
  tiles: TileData[],
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  return tiles.sort((a, b) => {
    return inBedStatusCompare(a, b, bedOccupancy, localized);
  });
};

export const inBedStatusCompare = (
  a: TileData,
  b: TileData,
  bedOccupancy: models.BedOccupancy[],
  localized: StringsIntl
) => {
  const aStatus = inBedStatus(a, bedOccupancy);
  const bStatus = inBedStatus(b, bedOccupancy);

  if (aStatus !== bStatus) {
    if (aStatus === 'in') {
      return 1;
    }
    if (bStatus === 'in') {
      return -1;
    }
  }
  return Residents.roomCompare(a.resident, b.resident, localized);
};

export const movementLevel = (tile: TileData): number | undefined => {
  if (tile.aisleepVitals) {
    return Aisleep.aisleepMovementLevel(tile.db, tile.aisleepVitals);
  } else if (tile.sensingWaveVitals) {
    return SensingWave.movementLevel(tile.db, tile.sensingWaveVitals);
  } else {
    return undefined;
  }
};

export const sleepStatus = (
  tile: TileData,
  bedOccupancy: models.BedOccupancy[]
): SleepStatusType => {
  if (tile.aisleepVitals) {
    return aisleepSleepStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals,
      bedOccupancy
    );
  } else if (tile.sensingWaveVitals) {
    return sensingWaveSleepStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals,
      bedOccupancy
    );
  } else {
    return 'na';
  }
};

export const sittingStatus = (tile: TileData): SittingStatusType => {
  if (tile.aisleepVitals) {
    return aisleepSittingStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals
    );
  } else if (tile.sensingWaveVitals) {
    return sensingWaveSittingStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals
    );
  } else {
    return 'na';
  }
};

export const isStatusUnknown = (tile: TileData): boolean | undefined => {
  if (tile.aisleepVitals) {
    return Aisleep.aisleepIsStatusUnknown(tile.db, tile.aisleepVitals);
  } else if (tile.sensingWaveVitals) {
    return SensingWave.isStatusUnknown(tile.db, tile.sensingWaveVitals);
  } else {
    return undefined;
  }
};

export const isStatusNoSignal = (tile: TileData): boolean | undefined => {
  if (tile.aisleepVitals) {
    return Aisleep.aisleepIsStatusNoSignal(tile.db, tile.aisleepVitals);
  } else {
    return undefined;
  }
};

export const aisleepSittingStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.AisleepVitals | undefined
): SittingStatusType => {
  let status: SittingStatusType = 'na';

  if (vitals && vitals.vitals) {
    status = Aisleep.aisleepSittingStatus(db, vitals);
  }

  return status;
};

export const sensingWaveSittingStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.SensingWaveVitals | undefined
): SittingStatusType => {
  let status: SittingStatusType = 'na';

  if (vitals && vitals.vitals) {
    status = SensingWave.sittingStatus(db, vitals);
  }

  return status;
};

export const inBedStatus = (
  tile: TileData,
  bedOccupancy: models.BedOccupancy[]
): InBedStatusType => {
  if (tile.aisleepVitals) {
    logger.debug('inBedStatus: checking AISLEEEP');
    return aisleepInBedStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.aisleepVitals,
      bedOccupancy
    );
  } else if (tile.sensingWaveVitals) {
    logger.debug('inBedStatus: checking SENSING_WAVE');
    return sensingWaveInBedStatus(
      tile.db,
      tile.center,
      tile.resident,
      tile.sensors,
      tile.sensingWaveVitals,
      bedOccupancy
    );
  } else {
    return 'na';
  }
};

export const tilesWithInBedStatsStatus = (
  tileData: TileData[],
  bedOccupancy: models.BedOccupancy[],
  status: InBedStatusType
) => {
  const tiles = tileData.filter((t) => inBedStatus(t, bedOccupancy) === status);
  return tiles;
};

export const tilesWithoutInBedStatus = (
  tileData: TileData[],
  bedOccupancy: models.BedOccupancy[],
  status: InBedStatusType
) => {
  const tiles = tileData.filter((t) => inBedStatus(t, bedOccupancy) !== status);
  return tiles;
};

export const hasAlerts = (tile: TileData) =>
  tile.alerts.filter((a) => !a.isOneClickHidden()).length > 0;

export const hasNurseCalls = (tile: TileData) =>
  tile.alerts.filter((a) => a.alertWithSensorDetail.alert.data.alertType === AlertType.NURSE_CALL)
    .length > 0;

export const onlyHasNurseCalls = (tile: TileData) => {
  const a = tile.alerts.filter((a) => !a.isOneClickHidden()).length;
  const n = tile.alerts.filter(
    (a) => a.alertWithSensorDetail.alert.data.alertType === AlertType.NURSE_CALL
  ).length;
  if (a === n) return true;
  else return false;
};

export const hasWarnings = (tile: TileData, bedOccupancy: models.BedOccupancy[]) =>
  tile.resident.data.warningsDisabled !== true &&
  (inBedStatus(tile, bedOccupancy) === 'out-warning' ||
    sleepStatus(tile, bedOccupancy) === 'awake-warning');

export const hasAwakes = (tile: TileData, bedOccupancy: models.BedOccupancy[]) =>
  sleepStatus(tile, bedOccupancy) === 'awake' ||
  inBedStatus(tile, bedOccupancy) === 'out-warning' ||
  sleepStatus(tile, bedOccupancy) === 'awake-warning';

export const hasActiveSensors = (tile: TileData) =>
  (tile.bioBeatVitals && tile.bioBeatVitals.sensorIsActive) ||
  (tile.aisleepVitals && tile.aisleepVitals.sensorIsActive) ||
  (tile.sensingWaveVitals && tile.sensingWaveVitals.sensorIsActive);

/**
 * Returns true if the tile has sensors but none are active.
 */
export const hasNoActiveSensors = (tile: TileData): boolean => {
  if (tile.sensors.length > 0) {
    return !hasActiveSensors(tile);
  }
  return false;
};

/**
 * returns all tiles that have alerts.
 * @param tileData
 */
export const tilesWithAlerts = (tileData: TileData[]) => tileData.filter((t) => hasAlerts(t));

/**
 * returns all tiles that have in bed status of "out-warning"
 * or sleep status "awake-warning"
 * @param tileData
 */
export const tilesWithWarnings = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => hasWarnings(t, bedOccupancy));

/**
 * returns all tiles that dont' have in bed status of "out-warning"
 * and don't have sleep status "awake-warning"
 * @param tileData
 */
export const tilesWithoutWarnings = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => !hasWarnings(t, bedOccupancy));

/**
 * returns all tiles with "Awake"
 * @param tileData
 */
export const tilesWithAwakes = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => hasAwakes(t, bedOccupancy));

/**
 * returns all tiles without alerts and without in bed stats "Awake"
 * @param tileData
 */
export const tilesWithoutAwakes = (tileData: TileData[], bedOccupancy: models.BedOccupancy[]) =>
  tileData.filter((t) => !hasAwakes(t, bedOccupancy));

/**
 * returns all tiles without alerts and without in bed stats "out"
 * @param tileData
 */
export const tilesWithoutAlerts = (tileData: TileData[]) => tileData.filter((t) => !hasAlerts(t));

/**
 * returns all tiles witout active sensors
 * @param tileData
 */
export const tilesWithoutActiveSensors = (tileData: TileData[]) =>
  tileData.filter((t) => hasNoActiveSensors(t));

/**
 * returns all tiles with active sensors
 * @param tileData
 */
export const tilesWithActiveSensors = (tileData: TileData[]) =>
  tileData.filter((t) => hasActiveSensors(t));

/**
 * getAlerts returns the alerts related to the resident.
 */
export const getAlerts = (resident: models.Resident, alerts: Alerts.AlertViewData[]) => {
  return alerts.filter(
    (a) =>
      a.resident && a.resident.snapshot.id === resident.snapshot.id && a.isOpen() && !a.isFeedback()
  );
};

/**
 * getAlerts returns the alerts related to the resident.
 */
export const getFeedbackAlerts = (resident: models.Resident, alerts: Alerts.AlertViewData[]) => {
  return alerts.filter(
    (a) =>
      a.resident && a.resident.snapshot.id === resident.snapshot.id && a.isOpen() && a.isFeedback()
  );
};

/**
 * sortAlerts sorts alerts in the order that they should be presented in the dashboard.
 */
export const sortAlerts = (alerts: Alerts.AlertViewData[], localized: StringsIntl) => {
  return alerts.sort((x, y) => Alerts.alertCompare(x, y, localized));
};

export const getAlertPauseRemaining = (tile: TileData) => {
  const remaining = tile.db.residents.disabledAlertsTimeRemaining(tile.resident);

  if (remaining === undefined) {
    return -1;
  } else {
    return remaining;
  }
};

export const getAlertPauseRemainingPercent = (tile: TileData) => {
  const remaining = getAlertPauseRemaining(tile);
  if (remaining >= 0) {
    return Math.ceil((remaining / Residents.DEFAULT_ALERTS_PAUSED_DURATION) * 100);
  }
  return -1;
};

export const hasStatus = (tile: TileData) => {
  if (
    inBedStatus(tile, tile.bedOccupancy) !== 'na' ||
    (tile.bioBeatVitals && tile.bioBeatVitals.vitals) ||
    (tile.aisleepVitals && tile.aisleepVitals.vitals) ||
    (tile.sensingWaveVitals && tile.sensingWaveVitals.vitals)
  ) {
    return true;
  }
  return false;
};

export const tilesDifferOld = (
  listA: TileData[],
  listB: TileData[],
  localized: StringsIntl,
  checkAlerts = true,
  checkVitals = true,
  checkBle = true
): boolean => {
  logger.debug('tilesDiffer() - start');
  logger.debug('tilesDiffer()', { listA: listA.length, listB: listB.length });

  let diff = listA.length !== listB.length;
  if (!diff) {
    const sortedA = sortTilesByRoom(listA, localized);
    const sortedB = sortTilesByRoom(listB, localized);

    logger.debug('tilesDiffer()', {
      sortedA,
      sortedB,
    });

    diff = sortedA.some((a, idx) => {
      let _diff = false;
      const b: TileData = sortedB[idx];
      if (!_diff) {
        _diff = a.alerts.length !== b.alerts.length;
      }
      if (!_diff) {
        _diff = !deepEqual(a.resident.data, b.resident.data);
      }
      if (!_diff) {
        _diff = a.contextMenuIsOpen !== b.contextMenuIsOpen;
      }
      if (!_diff) {
        _diff = a.cameras.length !== b.cameras.length;
        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - cameras length changed');
        }
      }
      if (!_diff) {
        const aSorted = a.cameras.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
        const bSorted = b.cameras.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
        _diff = aSorted.some((a1, idx1) => {
          if (idx1 < bSorted.length) {
            const b1: models.Camera = bSorted[idx1];
            return a1.id !== b1.id;
          } else {
            return true;
          }
        });
      }
      if (!_diff) {
        _diff = a.resident.snapshot.id !== b.resident.snapshot.id;
        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - tile resident changed');
        }
      }
      if (!_diff) {
        _diff = a.sensorsStatus.length !== b.sensorsStatus.length;
        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - tile sensor status length changed');
        }
      }
      if (!_diff) {
        // statuses are alerady sorted. if we sort here first need to make a copy...
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        _diff = !deepEqual(a.sensorsStatus, b.sensorsStatus);
        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - tile sensor status changed', {
            before: a.sensorsStatus,
            after: b.sensorsStatus,
          });
        }
      }
      if (!_diff && checkAlerts) {
        _diff = a.alerts.length !== b.alerts.length;
        if (_diff) {
          logger.debug('tilesDiffer() - tile alerts length changed', { idx, a, b });
        } else {
          logger.debug('tilesDiffer() - tile alerts length same', { idx, a, b });
        }
      }
      if (!_diff && checkAlerts) {
        _diff = a.alerts.some((a1, idx1) => {
          const b1: Alerts.AlertViewData = b.alerts[idx1];
          if (a1.alertWithSensorDetail.alert.id !== b1.alertWithSensorDetail.alert.id) {
            return true;
          } else {
            // check if the sensor alert changed
            return !deepEqual(
              a1.alertWithSensorDetail.sensorAlert?.data,
              b1.alertWithSensorDetail.sensorAlert?.data
            );
          }
        });
        if (_diff) {
          logger.debug('tilesDiffer() - tile alerts differ');
        } else {
          logger.debug('tilesDiffer() - tile alerts same');
        }
      }
      // aisleep
      if (!_diff) {
        _diff =
          (a.aisleepVitals !== undefined && b.aisleepVitals === undefined) ||
          (a.aisleepVitals === undefined && b.aisleepVitals !== undefined);
        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - tile aisleep vitals changed 1');
        }
      }
      if (!_diff && a.aisleepVitals !== undefined && b.aisleepVitals !== undefined) {
        const aStatus = a.aisleepVitals.sensorStatus();
        const bStatus = b.aisleepVitals.sensorStatus();

        _diff =
          aStatus !== bStatus ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          !deepEqual(a.aisleepVitals.vitals.data.aisleep, b.aisleepVitals.vitals.data.aisleep);

        if (_diff && logger.isDebugEnabled()) {
          logger.debug('tilesDiffer() - tile aisleep vitals changed 2');
        }
      }
      // nurse call
      if (!_diff) {
        _diff = a.alerts.some((a1) => {
          if (a1.alertWithSensorDetail.alert.data.alertType === AlertType.NURSE_CALL) {
            const b1 = b.alerts.find(
              (b1) => b1.alertWithSensorDetail.alert.id === a1.alertWithSensorDetail.alert.id
            );
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.nurseCallCount !==
                a1.alertWithSensorDetail.alert.data.nurseCallCount
            ) {
              return true;
            }
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.alertStatusType !==
                a1.alertWithSensorDetail.alert.data.alertStatusType
            ) {
              return true;
            }
          }
          return false;
        });
      }
      // feedback
      _diff = a.feedbackAlerts.length !== b.feedbackAlerts.length;
      if (_diff && logger.isDebugEnabled()) {
        logger.debug('tilesDiffer() - feedback alerts length changed');
      }
      if (!_diff) {
        _diff = a.feedbackAlerts.some((a1) => {
          if (a1.alertWithSensorDetail.alert.data.alertType === AlertType.FEEDBACK) {
            const b1 = b.feedbackAlerts.find(
              (b1) => b1.alertWithSensorDetail.alert.id === a1.alertWithSensorDetail.alert.id
            );
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.feedback?.message !==
                a1.alertWithSensorDetail.alert.data.feedback?.message
            ) {
              return true;
            }
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.feedback?.type !==
                a1.alertWithSensorDetail.alert.data.feedback?.type
            ) {
              return true;
            }
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.feedback?.durationSec !==
                a1.alertWithSensorDetail.alert.data.feedback?.durationSec
            ) {
              return true;
            }
            if (
              !b1 ||
              b1.alertWithSensorDetail.alert.data.alertStatusType !==
                a1.alertWithSensorDetail.alert.data.alertStatusType
            ) {
              return true;
            }
          }
          return false;
        });
      }
      if (!_diff && checkVitals) {
        if (!_diff && a.bioBeatVitals !== undefined && b.bioBeatVitals !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          _diff = !deepEqual(a.bioBeatVitals, b.bioBeatVitals);
          if (_diff && logger.isDebugEnabled()) {
            logger.debug('tilesDiffer() - tile biobeat vitals changed');
          }
        }
      }
      if (checkBle && !_diff) {
        // was undefined, becomes defined.  case not handled for other vitals, may be unique to ble
        if (!_diff && a.bleVitals === undefined && b.bleVitals !== undefined) {
          _diff = true;
          if (_diff && logger.isDebugEnabled()) {
            logger.debug('tilesDiffer() - tile ble vitals changed (1)');
          }
        }
        if (!_diff && a.bleVitals !== undefined && b.bleVitals !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          _diff = !deepEqual(a.bleVitals, b.bleVitals);
          if (_diff && logger.isDebugEnabled()) {
            logger.debug('tilesDiffer() - tile ble vitals changed (2)');
          }
        }
      }
      logger.debug('tilesDiffer()', {
        diff: _diff,
        tile: idx,
        a: {
          resident: a.resident.id,
          alerts: a.alerts.length,
          feedbackAlerts: a.feedbackAlerts.length,
        },
        b: {
          resident: b.resident.id,
          alerts: b.alerts.length,
          feedbackAlerts: b.feedbackAlerts.length,
        },
      });
      return _diff;
    });
  }
  logger.debug(`tilesDiffer() - end: ${diff}`);
  return diff;
};

export const tileListsDiffer = (listA: TileData[], listB: TileData[]): boolean => {
  let diff = false;

  const sortedA = [...listA].sort((a, b) =>
    a.resident.snapshot.id.localeCompare(b.resident.snapshot.id)
  );
  const sortedB = [...listB].sort((a, b) =>
    a.resident.snapshot.id.localeCompare(b.resident.snapshot.id)
  );

  diff = diff || sortedA.length !== sortedB.length;
  diff = diff || sortedA.some((a, idx) => idx < sortedB.length && tileDiff(a, sortedB[idx]));

  diff && logger.debug('tilesDiffer()', { diff, listA, listB });
  return diff;
};

export const tileDiff = (a: TileData, b: TileData): boolean => {
  let diff = false;

  diff = diff || alertListDiff(a.alerts, b.alerts);
  diff = diff || alertListDiff(a.feedbackAlerts, b.feedbackAlerts);
  diff = diff || a.contextMenuIsOpen !== b.contextMenuIsOpen;
  diff = diff || cameraListDiff(a.cameras, b.cameras);
  diff = diff || aisleepVitalsDiff(a.aisleepVitals, b.aisleepVitals);
  diff = diff || residentDiff(a.resident, b.resident);
  diff = diff || sensorStatusListDiff(a.sensorsStatus, b.sensorsStatus);
  diff = diff || vitalsListDiff(a.bleVitals, b.bleVitals);

  diff && logger.debug('tileDiff()', { diff, a, b });
  return diff;
};

/**
 * Diff two residents.
 */
export const residentDiff = (a: models.Resident, b: models.Resident): boolean => {
  let diff = a.snapshot.id !== b.snapshot.id;
  if (!diff) {
    diff = !deepEqual(a.data, b.data);
  }

  diff && logger.debug('residentDiff()', { diff, a, b });
  return diff;
};

/**
 * Diff two aler lists
 */
export const alertListDiff = (a: Alerts.AlertViewData[], b: Alerts.AlertViewData[]): boolean => {
  let diff = a.length !== b.length;
  diff = diff || a.some((a1ertA, idx1) => alertDiff(a1ertA, b[idx1]));

  diff && logger.debug('alertListDiff()', { diff, a, b });
  return diff;
};

/**
 * Diff two alerts.
 */
export const alertDiff = (a: Alerts.AlertViewData, b: Alerts.AlertViewData): boolean => {
  let diff = a.alertWithSensorDetail.alert.id !== b.alertWithSensorDetail.alert.id;
  diff = diff || !deepEqual(a.alertWithSensorDetail.alert.data, b.alertWithSensorDetail.alert.data);
  diff =
    diff ||
    // check if the sensor alert data changed
    !deepEqual(
      a.alertWithSensorDetail.sensorAlert?.data,
      b.alertWithSensorDetail.sensorAlert?.data
    );

  diff && logger.debug('alertDiff()', { diff, a, b });
  return diff;
};

export const cameraListDiff = (a: models.Camera[], b: models.Camera[]): boolean => {
  const aSorted = a.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
  const bSorted = b.sort((x, y) => x.data.cameraId.localeCompare(y.data.cameraId));
  let diff = aSorted.length !== bSorted.length;
  diff = diff || aSorted.some((a1, i) => i < bSorted.length && cameraDiff(a1, bSorted[i]));

  diff && logger.debug('cameraListDiff()', { diff, a, b });
  return diff;
};

export const cameraDiff = (a: models.Camera, b: models.Camera): boolean => {
  let diff = a.id !== b.id;
  diff = diff || !deepEqual(a.data, b.data);

  diff && logger.debug('cameraDiff()', { diff, a, b });
  return diff;
};

export const sensorStatusListDiff = (
  a: models.SensorStatus[],
  b: models.SensorStatus[]
): boolean => {
  const aSorted = a.sort((x, y) => x.localeCompare(y));
  const bSorted = b.sort((x, y) => x.localeCompare(y));
  let diff = aSorted.length !== bSorted.length;
  if (!diff) {
    diff =
      diff ||
      aSorted.some((status, i) => i < bSorted.length && sensorStatusDiff(status, bSorted[i]));
  }

  diff && logger.debug('sensorStatusListDiff()', { diff, a: aSorted, b: bSorted });
  return diff;
};

export const sensorStatusDiff = (a: models.SensorStatus, b: models.SensorStatus): boolean => {
  const diff = a !== b;

  diff && logger.debug('sensorStatusDiff()', { diff, a, b });
  return diff;
};

export const aisleepVitalsDiff = (a?: Vitals.AisleepVitals, b?: Vitals.AisleepVitals): boolean => {
  let diff = false;

  diff = diff || (a !== undefined && b === undefined) || (a === undefined && b !== undefined);

  if (!diff && a !== undefined && b !== undefined) {
    const aStatus = a.sensorStatus();
    const bStatus = b.sensorStatus();

    diff = diff || aStatus !== bStatus;
    diff = diff || !deepEqual(a.vitals.data.aisleep, b.vitals.data.aisleep);
  }

  diff && logger.debug('aisleepVitalsDiff()', { diff, a, b });
  return diff;
};

export const vitalsListDiff = (a?: types.acApi.Vitals[], b?: types.acApi.Vitals[]): boolean => {
  let diff = false;
  diff = diff || (a !== undefined && b === undefined) || (a === undefined && b !== undefined);

  if (!diff && a !== undefined && b !== undefined) {
    diff = diff || a.length !== b.length;
    diff = diff || a.some((a1, idx) => vitalsDiff(a1, b[idx]));
  }

  diff && logger.debug('vitalsListDiff()', { diff, a, b });
  return diff;
};

export const vitalsDiff = (a: types.acApi.Vitals, b: types.acApi.Vitals): boolean => {
  let diff = false;
  diff = diff || !deepEqual(a, b);

  diff && logger.debug('vitalsDiff()', { diff, a, b });
  return diff;
};
