"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorMessageFromUnknown = exports.errorMessageFromResponse = exports.errorMessage = exports.isResponse = exports.ErrorCodes = exports.HttpStatusCodes = void 0;
exports.isError = isError;
__exportStar(require("./ACError"), exports);
__exportStar(require("./ACErrorCodes"), exports);
const models_1 = require("attentive-connect-api-fetch/dist/models");
Object.defineProperty(exports, "ErrorCodes", { enumerable: true, get: function () { return models_1.ErrorMessageCodeEnum; } });
const http_status_codes_1 = require("http-status-codes");
Object.defineProperty(exports, "HttpStatusCodes", { enumerable: true, get: function () { return http_status_codes_1.StatusCodes; } });
const ACError_1 = require("./ACError");
const ACErrorCodes_1 = require("./ACErrorCodes");
const ErrorMessage_1 = __importStar(require("./ErrorMessage"));
// type guard
function isError(error) {
    const _error = error;
    return _error.message !== undefined;
}
/**
 * Check if error is a fetch response object.
 *
 * @param e
 * @returns
 */
const isResponse = (e) => {
    const response = e;
    return (response.status !== undefined &&
        response.statusText !== undefined &&
        (response.ok === true || response.ok === false) &&
        typeof response.json === 'function');
};
exports.isResponse = isResponse;
const errorMessage = (e, code, message) => __awaiter(void 0, void 0, void 0, function* () {
    if ((0, ErrorMessage_1.isErrorMessage)(e) || (0, ACError_1.isACError)(e)) {
        return e;
    }
    else if ((0, exports.isResponse)(e)) {
        return (0, exports.errorMessageFromResponse)(e, code, message);
    }
    else {
        return (0, exports.errorMessageFromUnknown)(e, code, message);
    }
});
exports.errorMessage = errorMessage;
/**
 * A generic error handler for calls to Aisleep API. If an error occured during the API
 * request, then returns an error (ACError).
 *
 * @param response response object from an API call.
 */
const errorMessageFromResponse = (response, code, message) => response
    .json()
    .then((data) => (0, ErrorMessage_1.isErrorMessage)(data) || (0, ACError_1.isACError)(data)
    ? data
    : new ErrorMessage_1.default(response.status, code ? code : ACErrorCodes_1.ACErrorCodes.ErrorInternal, message ? message : 'an aisleep error occurred', { status: response.status, statusText: response.statusText }, data));
exports.errorMessageFromResponse = errorMessageFromResponse;
const errorMessageFromUnknown = (e, code, message) => {
    return new ErrorMessage_1.default(http_status_codes_1.StatusCodes.INTERNAL_SERVER_ERROR, code ? code : ACErrorCodes_1.ACErrorCodes.ErrorInternal, message ? message : 'an unknown aisleep error occured', {}, e);
};
exports.errorMessageFromUnknown = errorMessageFromUnknown;
