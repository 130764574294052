import { useState, useEffect } from 'react';
import {
  // AC-1482 Hide language settings from the menu
  // Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  UserPerson,
  // UserPersonTheme,
  UserProfile,
} from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icons } from '../';
import strings from '../../languages';
import * as redux from '../../redux';
import { FONT_SIZE_DEFAULT, FONT_SIZE_LARGE, FONT_SIZE_SMALL } from '../../themes/constants';
import Dialog from './Dialog';
import { CancelButton } from '../buttons';
// AC-1482 Hide language settings from the menu
// import { Language, setLanguage } from '../../redux/locale';

// type ThemeSwitch = boolean;

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  open: boolean;
  dialogType?: 'default' | 'sort' | 'fontSize' | 'language' | 'darkMode' | undefined;
  onClose: () => void;
}

interface StateProps {
  // Display Settings
  db?: Database;
  userPerson?: UserPerson;
  userProfile?: UserProfile;
  // themeName?: UserPersonTheme;
  darkMode?: string;
  fontSize?: number;
  // AC-1482 Hide language settings from the menu
  // language: Language;
  // setThemeName: (name: UserPersonTheme) => void;
  setDarkMode: (mode: string) => void;
  setFontSize: (size: number) => void;
  selectUser: (user: UserPerson) => void;
  // AC-1482 Hide language settings from the menu
  // setLanguage: typeof setLanguage;

  // Dashboard Settings
  showVitals: boolean;
  showBleVitals: boolean;
  showAlertsList: boolean;
  sortByRoom: boolean;
  playVerbalNotifications: boolean;
  muteAlerts: boolean;
  muteWarnings: boolean;
  disableWarnings: boolean;
  setShowVitals: (show: boolean) => void;
  setShowBleVitals: (show: boolean) => void;
  setShowAlertsList: (show: boolean) => void;
  setSortByRoom: (sort: boolean) => void;
  setPlayVerbalNotifications: (play: boolean) => void;
  setMuteAlerts: (mute: boolean) => void;
  setMuteWarnings: (mute: boolean) => void;
  setDisableWarnings: (disabled: boolean) => void;
}

// interface State {
//   languageDialogIsOpen: boolean;
//   sortDialogIsOpen: boolean;
// }

const mapStateToProps = (state: redux.ApplicationState) => ({
  // Display Settings
  db: state.auth.database,
  userProfile: state.auth.userProfile,
  userPerson: state.auth.userPerson,
  // themeName: state.layout.themeName,
  darkMode: state.layout.darkMode,
  fontSize: state.layout.fontSize,
  language: state.locale.language,

  // Dashboard Settings
  hideWarnings: state.dashboard.hideWarnings,
  showVitals: state.dashboard.showVitals,
  showBleVitals: state.dashboard.showBleVitals,
  showAlertsList: state.dashboard.showAlertsList,
  sortByRoom: state.dashboard.sortByRoom,
  playVerbalNotifications: state.dashboard.playVerbalNotifications,
  bedOccupancy: state.dashboard.bedOccupancy,
  muteAlerts: state.dashboard.muteAlerts,
  muteWarnings: state.dashboard.muteWarnings,
  disableWarnings: state.dashboard.disableWarnings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // Display Settings
  // setThemeName: (name: UserPersonTheme) => dispatch(redux.layout.setThemeName(name)),
  setDarkMode: (mode: string) => dispatch(redux.layout.setDarkMode(mode)),
  setFontSize: (size: number) => dispatch(redux.layout.setFontSize(size)),
  selectUser: (userPerson: UserPerson) => dispatch(redux.auth.selectPerson(userPerson)),
  // AC-1482 Hide language settings from the menu
  // setLanguage: (language: Language) => dispatch(setLanguage(language)),

  // Dashboard Settings
  setShowVitals: (show: boolean) => dispatch(redux.dashboard.showVitals(show)),
  setShowBleVitals: (show: boolean) => dispatch(redux.dashboard.showBleVitals(show)),
  setShowAlertsList: (show: boolean) => dispatch(redux.dashboard.showAlertsList(show)),
  setSortByRoom: (sort: boolean) => dispatch(redux.dashboard.sortByRoom(sort)),
  setPlayVerbalNotifications: (play: boolean) =>
    dispatch(redux.dashboard.playVerbalNotifications(play)),
  setMuteAlerts: (mute: boolean) => dispatch(redux.dashboard.muteAlerts(mute)),
  setMuteWarnings: (mute: boolean) => dispatch(redux.dashboard.muteWarnings(mute)),
  setDisableWarnings: (disabled: boolean) => dispatch(redux.dashboard.disableWarnings(disabled)),
});

type Props = ComponentProps & WrappedComponentProps & StateProps;

export const UserSettingsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl((props: Props) => {
    const [canSetting, setCanSetting] = useState(false);
    useEffect(() => {
      if (props.db) {
        void props.db.authz.canSetting().then((canSetting) => setCanSetting(canSetting));
      } else {
        setCanSetting(false);
      }
    }, [props.userProfile]);
    const localized = strings(props.intl);
    const isLanguageDialog = props.dialogType === 'language';
    const isSortDialog = props.dialogType === 'sort';
    const isFontSizeDialog = props.dialogType === 'fontSize';
    const isDarkModeDialog = props.dialogType === 'darkMode';
    const useBackButton =
      !isLanguageDialog && !isSortDialog && !isFontSizeDialog && !isDarkModeDialog;
    // AC-1482 Hide language settings from the menu
    // const [languageDialogIsOpen, setLanguageDialogIsOpen] = useState<boolean>(isLanguageDialog);
    const [sortDialogIsOpen, setSortDialogIsOpen] = useState<boolean>(isSortDialog);
    const [fontSizeDialogIsOpen, setFontSizeDialogIsOpen] = useState<boolean>(isFontSizeDialog);
    const [darkModeDialogIsOpen, setDarkModeDialogIsOpen] = useState<boolean>(isDarkModeDialog);
    const handleMuteAlerts = (_: React.ChangeEvent<HTMLInputElement>, sound: boolean) => {
      props.setMuteAlerts(!sound);
    };

    const handleDisableWarnings = (_: React.ChangeEvent<HTMLInputElement>, notify: boolean) => {
      props.setDisableWarnings(!notify);
    };

    const handleMuteWarnings = (_: React.ChangeEvent<HTMLInputElement>, sound: boolean) => {
      props.setMuteWarnings(!sound);
    };

    const handleShowVitals = (_: React.ChangeEvent<HTMLInputElement>, show: boolean) => {
      props.setShowVitals(show);
    };

    const onBack = () => {
      if (useBackButton) {
        // AC-1482 Hide language settings from the menu
        // setLanguageDialogIsOpen(isLanguageDialog);
        setSortDialogIsOpen(isSortDialog);
        setFontSizeDialogIsOpen(isFontSizeDialog);
        setDarkModeDialogIsOpen(isDarkModeDialog);
      } else {
        onClose();
      }
    };

    const onClose = () => {
      props.onClose();
    };

    const getFontSizeName = (): string => {
      const { fontSize } = props;
      if (fontSize !== undefined && fontSize <= FONT_SIZE_SMALL) {
        return localized.settings.fontSizeSmall();
      } else if (fontSize !== undefined && fontSize >= FONT_SIZE_LARGE) {
        return localized.settings.fontSizeLarge();
      } else {
        return localized.settings.fontSizeMedium();
      }
    };

    const getDarkModeName = (): string => {
      const { darkMode } = props;
      if (darkMode === 'dark') {
        return localized.settings.darkModeDark();
      } else if (darkMode === 'light') {
        return localized.settings.darkModeLight();
      } else {
        return localized.settings.darkModeAuto();
      }
    };

    const getFontSizeNumber = (): number => {
      const { fontSize } = props;
      if (fontSize !== undefined && fontSize <= FONT_SIZE_SMALL) {
        return 1;
      } else if (fontSize !== undefined && fontSize >= FONT_SIZE_LARGE) {
        return 3;
      } else {
        return 2;
      }
    };

    const setFontSize = (newFontSize: number) => {
      const { db, userPerson } = props;
      if (db && userPerson) {
        let fontSize = FONT_SIZE_DEFAULT;
        switch (newFontSize) {
          case 1:
            fontSize = FONT_SIZE_SMALL;
            break;
          case 3:
            fontSize = FONT_SIZE_LARGE;
            break;
          default:
            fontSize = FONT_SIZE_DEFAULT;
        }
        userPerson.data.fontSize = fontSize;
        void db.userPersons.update(userPerson).then(() => {
          props.setFontSize(fontSize);
          props.selectUser(userPerson);
        });
      }
      // setFontSizeDialogIsOpen(false);
    };

    const setDarkMode = (mode: string) => {
      const { db, userPerson } = props;
      if (db && userPerson) {
        userPerson.data.darkMode = mode;
        void db.userPersons.update(userPerson).then(() => {
          props.setDarkMode(mode);
          props.selectUser(userPerson);
        });
      }
      // setDarkModeDialogIsOpen(false);
    };

    // const setTheme = (newTheme: ThemeSwitch) => {
    //   const { db, userPerson } = props;
    //   if (db && userPerson) {
    //     let theme = UserPersonTheme.light;
    //     if (newTheme) theme = UserPersonTheme.dark;
    //     userPerson.data.theme = theme;
    //     void db.userPersons.update(userPerson).then(() => {
    //       props.setThemeName(theme);
    //       props.selectUser(userPerson);
    //     });
    //   }
    // };

    // const handleTheme = (event: React.ChangeEvent<HTMLInputElement>, newTheme: ThemeSwitch) => {
    //   setTheme(newTheme);
    // };

    // AC-1482 Hide language settings from the menu
    // const getLanguageName = (language: Language) => {
    //   const { intl } = props;
    //   const localized = strings(intl);

    //   switch (language) {
    //     case 'zh-HANT':
    //       return localized.language.zh_hant();
    //     case 'zh-HANS':
    //       return localized.language.zh_hans();
    //     case 'en-US':
    //       return localized.language.english();
    //     case 'he':
    //       return localized.language.hebrew();
    //     case 'pt':
    //       return localized.language.portuguese();
    //     case 'ja-JP':
    //     default:
    //       return localized.language.japanese();
    //   }
    // };

    // AC-1482 Hide language settings from the menu
    // const languages = (): Language[] => {
    //   // return ['ja-JP', 'en-US', 'pt', 'zh-HANT', 'zh-HANS'];
    //   return ['ja-JP', 'en-US'];
    // };

    // const languageSelection = (): Language[] => {
    //   const { language } = props;
    //   const selection: Language[] = [];
    //   languages()
    //     .sort()
    //     .forEach((l) => {
    //       if (language !== l) {
    //         selection.push(l);
    //       }
    //     });
    //   return selection;
    // };

    // AC-1482 Hide language settings from the menu
    // const setLanguage = (lang: Language) => {
    //   props.setLanguage(lang);
    //   // setLanguageDialogIsOpen(false);
    //   // props.onClose();
    // };

    const setSort = (sort: boolean) => {
      props.setSortByRoom(sort);
      // setSortDialogIsOpen(false);
      // props.onClose();
    };

    const revertSettings = () => {
      props.setMuteAlerts(false);
      props.setSortByRoom(true);
      props.setMuteWarnings(true);
      props.setDisableWarnings(true);
      props.setShowVitals(true);
      // setTheme(false);
      setFontSize(2);
      setDarkMode('system');
    };

    const backButton = useBackButton ? (
      <Button startIcon={<Icons.Back />} onClick={onBack} color="inherit" className="AC-backButton">
        {localized.report.settings()}
      </Button>
    ) : null;

    const listCheck = (
      <ListItemSecondaryAction>
        <Icons.Check />
      </ListItemSecondaryAction>
    );

    // AC-1482 Hide language settings from the menu
    // if (languageDialogIsOpen) {
    //   return (
    //     <Dialog
    //       // sx={{ ...props.sx }}
    //       open={props.open}
    //       onClose={onBack}
    //       aria-labelledby="language-settings-dialog-title"
    //     >
    //       <DialogTitle id="language-settings-dialog-title">
    //         {backButton}
    //         <Icons.Language />
    //         <span>{localized.language.language()}</span>
    //       </DialogTitle>
    //       <DialogContent dividers className="AC-settingsContent">
    //         <Stack className="AC-infoSpacing">
    //           {/* Language */}
    //           <List className="AC-settingsList">
    //             {languages().map((lang) => (
    //               <Box key={lang}>
    //                 <ListItemButton onClick={() => setLanguage(lang)} key={lang}>
    //                   <ListItemIcon>
    //                     <Icons.Locale color="disabled" />
    //                   </ListItemIcon>
    //                   <ListItemText primary={getLanguageName(lang)} />
    //                   {props.language === lang ? listCheck : null}
    //                 </ListItemButton>
    //                 <Divider />
    //               </Box>
    //             ))}
    //             {/* {languageSelection()
    //               .sort((a, b) => a.localeCompare(b))
    //               .map((lang) => (
    //                 <>
    //                   <ListItemButton onClick={() => setLanguage(lang)} key={lang}>
    //                     <ListItemIcon>
    //                       <Icons.Locale color="disabled" />
    //                     </ListItemIcon>
    //                     <ListItemText primary={getLanguageName(lang)} />
    //                   </ListItemButton>
    //                   <Divider />
    //                 </>
    //               ))} */}
    //           </List>
    //         </Stack>
    //       </DialogContent>
    //       <DialogActions>
    //         <CancelButton
    //           onClick={onBack}
    //           label={isLanguageDialog ? localized.action.close() : localized.action.back()}
    //         />
    //       </DialogActions>
    //     </Dialog>
    //   );
    // } else
    if (sortDialogIsOpen) {
      return (
        <Dialog
          // sx={{ ...props.sx }}
          open={props.open}
          onClose={onBack}
          aria-labelledby="sort-settings-dialog-title"
        >
          <DialogTitle id="sort-settings-dialog-title">
            {backButton}
            <Icons.SortByCategory />
            <span>{localized.settings.sortDashboard()}</span>
          </DialogTitle>
          <DialogContent dividers className="AC-settingsContent">
            <Stack className="AC-infoSpacing">
              {/* Sort Dashboard */}
              <List className="AC-settingsList">
                <ListItemButton onClick={() => setSort(true)}>
                  <ListItemIcon>
                    <Icons.SortByRoom color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.byRoom()} />
                  {props.sortByRoom ? listCheck : null}
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setSort(false)}>
                  <ListItemIcon>
                    <Icons.SortByCategory color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.byStatus()} />
                  {!props.sortByRoom ? listCheck : null}
                </ListItemButton>
              </List>
            </Stack>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onBack} label={localized.action.back()} />
          </DialogActions>
        </Dialog>
      );
    } else if (darkModeDialogIsOpen) {
      return (
        <Dialog
          // sx={{ ...props.sx }}
          open={props.open}
          onClose={onBack}
          aria-labelledby="font-size-settings-dialog-title"
        >
          <DialogTitle id="font-size-settings-dialog-title">
            {backButton}
            <Icons.DarkMode />
            <span>{localized.settings.darkMode()}</span>
          </DialogTitle>
          <DialogContent dividers className="AC-settingsContent">
            <Stack className="AC-infoSpacing">
              {/* Dark Mode */}
              <List className="AC-settingsList">
                <ListItemButton onClick={() => setDarkMode('light')}>
                  <ListItemIcon>
                    <Icons.LightMode color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.darkModeLight()} />
                  {props.darkMode === 'light' ? listCheck : null}
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setDarkMode('dark')}>
                  <ListItemIcon>
                    <Icons.DarkMode color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.darkModeDark()} />
                  {props.darkMode === 'dark' ? listCheck : null}
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setDarkMode('system')}>
                  <ListItemIcon>
                    <Icons.AutoMode color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.darkModeAuto()} />
                  {props.darkMode === 'system' || !props.darkMode ? listCheck : null}
                </ListItemButton>
              </List>
            </Stack>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onBack} label={localized.action.back()} />
          </DialogActions>
        </Dialog>
      );
    } else if (fontSizeDialogIsOpen) {
      return (
        <Dialog
          // sx={{ ...props.sx }}
          open={props.open}
          onClose={onBack}
          aria-labelledby="font-size-settings-dialog-title"
        >
          <DialogTitle id="font-size-settings-dialog-title">
            {backButton}
            <Icons.FontSize />
            <span>{localized.settings.fontSize()}</span>
          </DialogTitle>
          <DialogContent dividers className="AC-settingsContent">
            <Stack className="AC-infoSpacing">
              {/* Font Size */}
              <List className="AC-settingsList">
                <ListItemButton onClick={() => setFontSize(1)}>
                  <ListItemIcon>
                    <Icons.FontSize color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.fontSizeSmall()} />
                  {getFontSizeNumber() === 1 ? listCheck : null}
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setFontSize(2)}>
                  <ListItemIcon>
                    <Icons.FontSize color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.fontSizeMedium()} />
                  {getFontSizeNumber() === 2 ? listCheck : null}
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setFontSize(3)}>
                  <ListItemIcon>
                    <Icons.FontSize color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={localized.settings.fontSizeLarge()} />
                  {getFontSizeNumber() === 3 ? listCheck : null}
                </ListItemButton>
              </List>
            </Stack>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onBack} label={localized.action.back()} />
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          // sx={{ ...props.sx }}
          open={props.open}
          onClose={onClose}
          aria-labelledby="system-settings-dialog-title"
        >
          <DialogTitle id="system-settings-dialog-title">
            <Icons.Settings />
            <span>{localized.report.settings()}</span>
          </DialogTitle>
          <DialogContent dividers className="AC-settingsContent">
            <Stack className="AC-infoSpacing">
              {/* Notifications */}
              {canSetting ? (
                <Stack>
                  <Typography variant="caption" className="AC-settingsListCaption">
                    {localized.settings.notificationSettings()}
                  </Typography>
                  <List className="AC-settingsList">
                    <ListItem>
                      <ListItemIcon>
                        <Icons.SoundOn />
                      </ListItemIcon>
                      <ListItemText primary={localized.settings.noticeSounds()} />
                      <ListItemSecondaryAction>
                        <Switch
                          aria-label="notice-sounds-setting"
                          checked={!props.muteAlerts}
                          onChange={handleMuteAlerts}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <Icons.InBedAwake />
                      </ListItemIcon>
                      <ListItemText primary={localized.settings.noticeAwake()} />
                      <ListItemSecondaryAction>
                        <Switch
                          aria-label="notice-awake-setting"
                          checked={!props.disableWarnings}
                          onChange={handleDisableWarnings}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem
                      className={
                        props.muteAlerts || props.disableWarnings ? 'AC-disabled' : undefined
                      }
                    >
                      <ListItemIcon>
                        <Icons.SoundOn />
                      </ListItemIcon>
                      <ListItemText primary={localized.settings.awakeSounds()} />
                      <ListItemSecondaryAction>
                        <Switch
                          aria-label="awake-sounds-setting"
                          checked={
                            props.muteAlerts || props.disableWarnings ? false : !props.muteWarnings
                          }
                          onChange={handleMuteWarnings}
                          disabled={props.muteAlerts || props.disableWarnings}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Stack>
              ) : null}
              {/* Dashboard */}
              {canSetting ? (
                <Stack>
                  <Typography variant="caption" className="AC-settingsListCaption">
                    {localized.settings.dashboardSettings()}
                  </Typography>
                  <List className="AC-settingsList">
                    <ListItem>
                      <ListItemIcon>
                        <Icons.SortByCategory />
                      </ListItemIcon>
                      <ListItemText primary={localized.settings.sortDashboard()} />
                      <ListItemSecondaryAction className="AC-selectListItemAction">
                        <Button
                          endIcon={<Icons.Forward />}
                          onClick={() => {
                            setSortDialogIsOpen(true);
                          }}
                          color="inherit"
                        >
                          {props.sortByRoom
                            ? localized.settings.byRoom()
                            : localized.settings.byStatus()}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemIcon>
                        <Icons.Vitals />
                      </ListItemIcon>
                      <ListItemText primary={localized.settings.displayVitals()} />
                      <ListItemSecondaryAction>
                        <Switch
                          aria-label="display-vitals-setting"
                          checked={props.showVitals}
                          onChange={handleShowVitals}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </List>
                </Stack>
              ) : null}
              {/* Display */}
              <Stack>
                <Typography variant="caption" className="AC-settingsListCaption">
                  {localized.settings.displaySettings()}
                </Typography>
                <List className="AC-settingsList">
                  {/* <ListItem>
                    <ListItemIcon>
                      <Icons.DarkMode />
                    </ListItemIcon>
                    <ListItemText primary={localized.settings.darkMode()} />
                    <ListItemSecondaryAction>
                      <Switch
                        aria-label="dark-mode-setting"
                        // checked={getTheme()}
                        checked={props.themeName == 'dark'}
                        onChange={handleTheme}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider /> */}
                  <ListItem>
                    <ListItemIcon>
                      <Icons.DarkMode />
                    </ListItemIcon>
                    <ListItemText primary={localized.settings.darkMode()} />
                    <ListItemSecondaryAction className="AC-selectListItemAction">
                      <Button
                        endIcon={<Icons.Forward />}
                        onClick={() => {
                          setDarkModeDialogIsOpen(true);
                        }}
                        color="inherit"
                      >
                        {getDarkModeName()}
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemIcon>
                      <Icons.FontSize />
                    </ListItemIcon>
                    <ListItemText primary={localized.settings.fontSize()} />
                    <ListItemSecondaryAction className="AC-selectListItemAction">
                      <Button
                        endIcon={<Icons.Forward />}
                        onClick={() => {
                          setFontSizeDialogIsOpen(true);
                        }}
                        color="inherit"
                      >
                        {getFontSizeName()}
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  {
                    // AC-1482 Hide language settings from the menu
                  }
                  {/* <ListItem>
                    <ListItemIcon>
                      <Icons.Language />
                    </ListItemIcon>
                    <ListItemText primary={localized.language.language()} />
                    <ListItemSecondaryAction className="AC-selectListItemAction">
                      <Button
                        endIcon={<Icons.Forward />}
                        onClick={() => {
                          setLanguageDialogIsOpen(true);
                        }}
                        color="inherit"
                      >
                        {getLanguageName(props.language)}
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem> */}
                </List>
              </Stack>
              {canSetting ? (
                <Button
                  variant="outlined"
                  fullWidth
                  color="error"
                  size="large"
                  onClick={revertSettings}
                >
                  <Icons.Undo sx={{ mr: 1 }} />
                  {localized.settings.revertToDefault()}
                </Button>
              ) : null}
            </Stack>
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose} label={localized.action.close()} />
          </DialogActions>
        </Dialog>
      );
    }
  })
);
