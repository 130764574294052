"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalFeedbackKeys = exports.NurseCallFeedbackKeys = void 0;
exports.isAlertData = isAlertData;
exports.isAlert = isAlert;
var NurseCallFeedbackKeys;
(function (NurseCallFeedbackKeys) {
    NurseCallFeedbackKeys["BestReha"] = "nursecall.bestreha";
})(NurseCallFeedbackKeys || (exports.NurseCallFeedbackKeys = NurseCallFeedbackKeys = {}));
var PersonalFeedbackKeys;
(function (PersonalFeedbackKeys) {
    PersonalFeedbackKeys["Birthday"] = "personal.birthday";
    PersonalFeedbackKeys["Note"] = "personal.note";
})(PersonalFeedbackKeys || (exports.PersonalFeedbackKeys = PersonalFeedbackKeys = {}));
function isAlertData(obj) {
    return (obj &&
        obj.alertType !== undefined &&
        obj.alertTime !== undefined &&
        obj.sensorType !== undefined &&
        obj.careCenterRef !== undefined &&
        obj.residentRef !== undefined);
}
function isAlert(obj) {
    return obj && obj.data !== undefined && isAlertData(obj.data) && obj.id !== undefined;
}
